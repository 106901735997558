angular.module(config.app.name).component('payment', {
    templateUrl: 'components/procedure/components/payment.component.html',
    bindings: {
        canGoBack: '<',
        getData: '&',
        setData: '&'
    },
    controller: function ($element, $scope, $timeout, $_procedures, toast, sessionStorageService) {
        var self = this;

        self.view = {
            title: 'Paiement',
            icon: 'payments',
            buttons: {
                isPaymentPage: true
            }
        };

        // steps : 
        // 1 : show payment details
        // 2 : show payment popup
        self.step = 0;

        self.hasPrepaidAccount = false;
        self.acceptRCGPayments = true; // if false, tarification libre
        // card             : carte bancaire via payline
        // prepaid-account : payment via the prepaid account (cash ..)
        self.paymentType = 'card'; // or 'prepaid-account'

        self.paymentVerifPending = false;
        self.paymentVerifTimeout = false;
        self.paymentVerifInterval = null;
        self.paymentVerifCount = 0;
        self.paymentVerifNb = 20;

        self.paymentVerifAlreadyVisited = false;

        self.$onInit = function () {

            console.log('>>> init', 't::payment');

            // simulate payment in progress
            self.step = 0;
            getWebAppPrepaidAccount();

            self.getData({
                type: 'procedureId',
                cb: procedureId => {
                    self.procedureId = procedureId;
                    self.getProcedureAmounts();
                }
            });
        };

        self.$onDestroy = function () {
            console.log('>>> component destroyed', 'error', 't::payment')
        };

        function initPaymentVerif() {
            self.paymentVerifPending = false;
            self.paymentVerifTimeout = false;
            self.paymentVerifCount = 0;
            if (self.paymentVerifInterval) {
                clearInterval(self.paymentVerifInterval);
            }
        }

        self.setPaymentType = function (type = null) {
            if (type && ['card', 'prepaid-account'].includes(type)) {
                self.type = type;
                if (self.type === 'card') {
                    self.step = 1;
                } else {
                    self.step = 2;
                }
            }//
        };

        function getWebAppPrepaidAccount() {
            $_procedures.getWebAppPrepaidAccount().then((res) => {
                if (res) {
                    self.hasPrepaidAccount = res.hasPrepaidAccount;
                    self.acceptRCGPayments = res.acceptRCGPayments || (!self.hasPrepaidAccount);
                    sessionStorageService.set('acceptRCGPayments', self.acceptRCGPayments);
                    sessionStorageService.set('hasPrepaidAccount', self.hasPrepaidAccount);
                    $timeout(() => $scope.$apply());
                }
            }).catch((err) => {
                console.log('>>> err :', err);
            });
        }

        self.getProcedureAmounts = function (paidOnly = false) {
            let method = paidOnly === true ? 'getPaid' : 'getProcedureAmounts';
            $_procedures[method](self.procedureId).then((res) => {
                if (paidOnly !== true) {
                    self.amounts = res;
                }
                if (res.paid) {
                    self.paid = res.paid;
                    self.setData({
                        type: 'paid',
                        data: {paid: self.paid}
                    });
                    if (self.paid === true) {
                        self.step = 3;
                    }
                }
                $timeout(() => $scope.$apply());
            }).catch((err) => {
                console.log('>>> err :', err);
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
            });
        };

        self.sendPaymentLink = function () {
            $_procedures.sendPaymentLink(self.procedureId, self.phone).then((res) => {
                if (res) {
                    toast.show('Le lien de paiement a bien été envoyé par SMS au ' + self.phone, 'success');
                    self.showPaymentVerif();
                }
            }).catch((err) => {
                console.log('>>> err :', err);
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
            });
        };

        self.getPaymentLink = function () {
            $_procedures.getProcedurePaymentLink(self.procedureId).then((res) => {
                if (res) {
                    self.paymentLink = res.redirectURL;
                    $timeout(() => {
                        $scope.$apply();
                    });
                    self.showPaymentVerif();
                }
            }).catch((err) => {
                console.log('>>> err :', err);
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
            });
        };

        self.createPaymentRequest = function () {
            $_procedures.createPaymentRequest(self.procedureId).then((res) => {
                toast.show('La demande de paiement a bien été créée.', 'success');
                self.showPaymentVerif();
            }).catch((err) => {
                console.log('>>> err :', err);
                toast.show(err && err.message ? err.message : DEFAULT_ERROR_MESSAGE, 'error');
            });
        };

        self.showPaymentVerif = function (interval = 10000) {
            initPaymentVerif();
            $timeout(() => {
                self.step = 3;
                self.paymentVerifPending = true;
                self.paymentVerifAlreadyVisited = true;
                self.paymentVerifInterval = setInterval(() => {
                    if (self.paymentVerifCount === self.paymentVerifNb || self.paid === true) {
                        self.paymentVerifPending = false;
                        self.paymentVerifTimeout = true;
                        clearInterval(self.paymentVerifInterval);
                        $timeout(() => $scope.$apply());
                    } else {
                        self.getProcedureAmounts(true);
                    }
                    self.paymentVerifCount++;
                }, 1000);
                $timeout(() => $scope.$apply());
            }, interval);
        };

        self.back = function () {
            if (self.step === 3) {
                if (self.paid === true) {
                    $element[0].dispatchEvent(new CustomEvent('navigation', {
                        detail: 'back'
                    }));
                    return;
                }
                initPaymentVerif();
                self.paymentVerifAlreadyVisited = true;
                if (self.type === 'card') {
                    self.step = 1;
                } else {
                    self.step = 2;
                }
            } else if (self.step > 0) {
                self.step = 0;
            } else {
                $element[0].dispatchEvent(new CustomEvent('navigation', {
                    detail: 'back'
                }));
            }
        };

        self.next = function () {
            self.setData({
                type: 'payment',
                data: {
                    payment: {
                        paid: self.paid
                    }
                }
            });
        };
    }
});